import React from "react";
// import { mockedUser } from "../mocks/user.mock";
import Loader from "../components/Loader";
import Toast from "../components/Toast";
import BottomDrawer from "../components/BottomDrawer";

export interface MainAppContextProps {
  user: User | null;
  isLoggedIn: boolean;
  isSeenNotification: boolean;
  setUser: (user: User, isLoggedIn: boolean) => void;
  showToast: (
    message: string,
    severity: "success" | "error" | "warning" | "info"
  ) => void;
  openDrawer: () => void;
  closeDrawer: () => void;
}

interface MainAppProviderProps {
  children: React.ReactNode;
}

type User = {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  photo_url: string;
  auth_date: number;
  hash: string;
};

type State = {
  user: User | null;
  isLoggedIn: boolean;
  loading: boolean;
  btDrawerIsOpen: boolean;
  toastIsOpen: boolean;
  toastMesage: string;
  toastSeverity: "success" | "error" | "warning" | "info";
  isSeenNotification: boolean;
};

export const MainAppContext = React.createContext<MainAppContextProps>({
  user: null,
  isLoggedIn: false,
  isSeenNotification: false,
  setUser: () => {},
  showToast: () => {},
  openDrawer: () => {},
  closeDrawer: () => {},
});

class MainAppProvider extends React.Component<MainAppProviderProps, State> {
  constructor(props: MainAppProviderProps) {
    super(props);
    this.state = {
      user: null,
      isLoggedIn: false,
      loading: true,
      btDrawerIsOpen: false,
      toastIsOpen: false,
      toastMesage: "",
      toastSeverity: "info",
      isSeenNotification: false,
    };
  }

  public componentDidMount() {
    // Getting User's Object
    const user = localStorage.getItem("user");
    // Getting User's Info Notification Status
    const isSeenNotification = localStorage.getItem("isSeenNotification");
    // this.setState({ user: mockedUser, isLoggedIn: true });
    if (user) {
      this.setState({ user: JSON.parse(user), isLoggedIn: true });
    }
    if (isSeenNotification) {
      //   localStorage.removeItem("isSeenNotification");
      this.setState({ isSeenNotification: true });
    }
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  }

  public setUser = (user: User, isLoggedIn: boolean) => {
    localStorage.setItem("user", JSON.stringify(user));
    this.setState({ user, isLoggedIn });
  };

  public handleDrawerOpen = () => {
    this.setState({ btDrawerIsOpen: true });
  };

  public handleDrawerClose = () => {
    if (this.state.isSeenNotification === false) {
      this.setState({ isSeenNotification: true });
      localStorage.setItem("isSeenNotification", "true");
    }
    this.setState({ btDrawerIsOpen: false });
  };

  public onDrawerOpen = () => {
    // TODO: Find Out Why It's Not Triggering
    // localStorage.removeItem("isSeenNotification");
    if (this.state.isSeenNotification === false) {
      this.setState({ isSeenNotification: true });
      localStorage.setItem("isSeenNotification", "true");
    }
    console.log('action!')
  };

  public handleToastClose = () => {
    this.setState({ toastIsOpen: false });
  };

  public showToast = (
    message: string,
    severity: "success" | "error" | "warning" | "info"
  ) => {
    this.setState({
      toastIsOpen: true,
      toastMesage: message,
      toastSeverity: severity,
    });
  };

  public render() {
    const { user, isLoggedIn, isSeenNotification } = this.state;
    return (
      <MainAppContext.Provider
        value={{
          user,
          isLoggedIn,
          isSeenNotification,
          setUser: this.setUser,
          showToast: this.showToast,
          openDrawer: this.handleDrawerOpen,
          closeDrawer: this.handleDrawerClose,
        }}
      >
        <>
          <Toast
            message={this.state.toastMesage}
            onClose={this.handleToastClose}
            severity={this.state.toastSeverity}
            isShowed={this.state.toastIsOpen}
          />
          {this.state.loading ? <Loader /> : this.props.children}
          <BottomDrawer
            isOpen={this.state.btDrawerIsOpen}
            onClose={this.handleDrawerClose}
            onOpen={this.onDrawerOpen}
          />
        </>
      </MainAppContext.Provider>
    );
  }
}

export default MainAppProvider;
