import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Alert,
  DialogActions,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import TelegramLoginButton from "../../components/TelegramLoginButton";
import { HourStatuses } from "../../types/hours.types";
import { MainAppContext } from "../../providers/MainAppProvider";

interface HourModalProps {
  isOpen: boolean;
  date: Date;
  hours: string[];
  firstName: string | undefined;
  lastName: string | undefined;
  bandName: string;
  onClose: () => void;
  onConfirm: () => void;
  onBandNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

class HourModal extends React.Component<HourModalProps> {
  static contextType = MainAppContext;

  constructor(
    props: HourModalProps,
    public context: React.ContextType<typeof MainAppContext>
  ) {
    super(props);
  }

  public render() {
    const { isLoggedIn } = this.context;
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!isLoggedIn ? (
          <DialogContent>
            <Alert severity="warning" variant="outlined" style={{ margin: "10px 0" }}>
              Для бронирования репетиций необходимо авторизоваться
            </Alert>
            <Alert severity="info" variant="outlined" style={{ margin: "10px 0" }}>
              Регистрация временно недоступна
            </Alert>
            {/* <TelegramLoginButton /> */}
          </DialogContent>
        ) : (
          <>
            <DialogTitle sx={{ textAlign: "left", alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
              
              <Typography variant="h6" fontSize={18}>
                Забронировать{" "}
                {this.props.date?.toLocaleString().split(",")[0]}
              </Typography>
            </DialogTitle>

            <DialogContent dividers>
              <div className="hourChecker">
                {this.props.hours.map((hour, i) => (
                  <button key={i} className={"hour " + HourStatuses.SELECTED}>
                    {hour}
                  </button>
                ))}
              </div>
              <TextField
                label="Ваше имя"
                disabled
                style={{ width: "100%", marginTop: 10 }}
                value={`${this.props.firstName} ${
                  this.props.lastName !== undefined && this.props.lastName
                }`}
              />
              <Accordion sx={{ margin: "10px 0" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>
                    Опционально можно ввести название коллектива
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    variant="outlined"
                    label="Название группы"
                    value={this.props.bandName}
                    onChange={this.props.onBandNameChange}
                    style={{ marginBottom: 10, width: "100%" }}
                  />
                </AccordionDetails>
              </Accordion>
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button onClick={this.props.onClose} color="error">Закрыть</Button>
          <Button
            onClick={this.props.onConfirm}
            autoFocus
            disabled={!isLoggedIn}
            color="success"
          >
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default HourModal;
