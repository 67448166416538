import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/App.css";
import NavMenu from "./components/NavMenu";
import Loader from "./components/Loader";
// import { InstallPWA } from "./components/InstallPrompt";
import MainAppProvider from "./providers/MainAppProvider";
// const Feed = lazy(() => import("./screens/Feed"));
import Feed from "./screens/Feed";
import InfoModal from "./components/InfoModal";
import Timetable from "./screens/Timetable";
const Media = lazy(() => import("./screens/Media"));

class App extends React.Component {
  render(): React.ReactNode {
      return (
        <MainAppProvider>
          <Router>
            {/* <InstallPWA /> */}
            <InfoModal />
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/" element={<Timetable />} />
                <Route path="/feed" element={<Feed />} />
                <Route path="/media" element={<Media />} />
              </Routes>
              {/* <NavMenu /> */}
            </Suspense>
          </Router>
        </MainAppProvider>
      );
  }
}

export default App;
