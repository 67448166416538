import React from "react";
import { HourStatuses } from "../../types/hours.types";
import { Avatar, CardHeader, Chip, Tooltip, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

interface HourButtonProps {
  hour: string;
  status: string;
  owner: string | null;
  isOwn: boolean;
  bandname: string | null;
  avatar: string | null;
  onClick: (hour: string, isOwn?: boolean) => void;
  isCancelling: boolean;
}

class HourButton extends React.Component<HourButtonProps> {
  constructor(props: HourButtonProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  public handleClick() {
    this.props.onClick(this.props.hour, this.props.isOwn);
  }

  public render() {
    const isDisabled =
      (this.props.status === HourStatuses.CONFIRMED ||
        this.props.status === HourStatuses.BOOKED) &&
      !this.props.isOwn;
    return (
      <Tooltip
        disableHoverListener={!this.props.owner}
        disableFocusListener={!this.props.owner}
        disableTouchListener={!this.props.owner}
        enterTouchDelay={0}
        title={
          <CardHeader
            sx={{ color: "#fff" }}
            avatar={
              <Avatar
                alt={this.props.owner!!}
                src={
                  this.props.avatar ? this.props.avatar : "/assets/vinyl.svg"
                }
                imgProps={{
                  sx: {
                    backgroundImage: "url(/assets/vinyl.svg)",
                    backgroundSize: "contain",
                  },
                }}
              />
            }
            title={
              <Typography
                gutterBottom
                noWrap
                sx={{ overflow: "hidden", color: "#fff" }}
              >
                {this.props.owner}
              </Typography>
            }
            subheader={
              <>
                {this.props.bandname &&
                  this.props.bandname !== undefined &&
                  this.props.bandname !== "" && (
                    <Chip
                      label={this.props.bandname}
                      variant="outlined"
                      sx={{
                        color: "#fff",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: 200,
                      }}
                    />
                  )}
                <Chip
                  icon={<AccessTimeIcon sx={{ fill: "#fff" }} />}
                  label={this.props.hour}
                  sx={{ color: "#fff" }}
                />
              </>
            }
          />
        }
      >
        <button
          className={
            "hour " +
            this.props.status +
            " " +
            (this.props.isCancelling ? "CANCELLING" : "")
          }
          disabled={isDisabled}
          onClick={this.handleClick}
        >
          {this.props.owner ? (
            <Avatar
              alt={this.props.owner}
              src={this.props.avatar ? this.props.avatar : "/assets/vinyl.svg"}
              imgProps={{
                sx: {
                  backgroundImage: "url(/assets/vinyl.svg)",
                  backgroundSize: "contain",
                  width: 15,
                  height: 15,
                },
              }}
              sx={{ width: 15, height: 15, border: "1px solid" }}
            />
          ) : (
            this.props.hour
          )}
        </button>
      </Tooltip>
    );
  }
}

export default HourButton;
