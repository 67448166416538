export interface HourType {
  value: string;
  isSelected: boolean;
  isBooked: boolean;
}

export interface HourState {
  [key: string]: {
    status: HourStatus;
    owner: string | null;
    userId: number | null,
    bandName: string | null;
    avatar: string | null;
    isCancelling: boolean;
  };
}

export enum HourStatuses {
  NONE = "NONE",
  SELECTED = "SELECTED",
  BOOKED = "BOOKED",
  CONFIRMED = "CONFIRMED",
  CANCELLING = "CANCELLING",
}

export type HourStatus = keyof typeof HourStatuses;
