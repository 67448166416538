import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import React from "react";

class InfoModal extends React.Component {
  state = {
    isSeen: false,
  };

  public componentDidMount(): void {
    const isSeen = localStorage.getItem("isSeen");
    this.setState({ isSeen });
  }

  public handlexitClick = () => {
    localStorage.setItem("isSeen", "true");
    this.setState({ isSeen: true });
  };

  render() {
    if(!this.state.isSeen) return null;
    return (
      <Dialog
        open={!this.state.isSeen}
        onClose={this.handlexitClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 0 }}>
          <img
            src="/logo_main192.png"
            className="feed_logo"
            width={80}
            alt="logo"
          />
        </DialogTitle>

        <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 0 }}>
          Галя, у нас отмена!
        </DialogTitle>
        <DialogContent>
          <Typography>Потихонечку обновляется наша прилага.</Typography>
          <Typography>В этой версии можно отменять бронирование.</Typography>
          <Typography>
            Календарь размещен теперь на первой вкладке, не надо будет
            постоянно переключаться.
          </Typography>
          <Typography>
            На второй вкладке находится информация о работе приложения и студии.
          </Typography>
          <Typography>
            Важные новости в работе студии и приложения будут появлятся в виде
            подобного окна, а затем будут видны на второй вкладке.
          </Typography>
          <Typography>
            В следущей версии ожидается модерация бронирований.
          </Typography>
          <Typography>Спасибо за внимание.</Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.handlexitClick}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default InfoModal;
