import React from "react";
import Alert from "@mui/material/Alert/Alert";
import Snackbar from "@mui/material/Snackbar/Snackbar";

interface ToastProps {
  isShowed: boolean;
  message: string;
  severity: "success" | "error" | "info" | "warning";
  onClose: () => void;
}
export default class Toast extends React.Component<ToastProps> {
  render() {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={this.props.isShowed}
        autoHideDuration={6000}
        onClose={this.props.onClose}
      >
        <Alert
          onClose={this.props.onClose}
          severity={this.props.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {this.props.message}
        </Alert>
      </Snackbar>
    );
  }
}
