import { Badge } from "@mui/material";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { Dayjs } from "dayjs";
import React from "react";

interface BookedDayProps extends PickersDayProps<Dayjs> {
  highlightedDays?: number[];
}

class BookedDay extends React.Component<BookedDayProps> {
  render() {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = this.props;
    const isSelected = !outsideCurrentMonth && highlightedDays.indexOf(day.date()) >= 0;
    return (
      <Badge
        key={day.toString()}
        overlap="circular"
        badgeContent={isSelected ? "🎸" : undefined}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }
}

export default BookedDay;
