import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { initialHoursState } from "../../mocks/hours.mock";
import { HourState, HourStatuses } from "../../types/hours.types";
import {
  API_URL,
  BOOK_URL,
  BOOKINGS_URL,
  CANCEL_URL,
  HOURS_URL,
} from "../../constants/endpoints";

import "react-calendar/dist/Calendar.css";
import Button from "@mui/material/Button";
import HourButton from "./HourButton.component";
import HourModal from "./HourModal.component";
import { MainAppContext } from "../../providers/MainAppProvider";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ru";
import { ruRU } from "@mui/x-date-pickers/locales";
import BookedDay from "./BookedDay.component";
import CancelModal from "./CancelModal.component";
import Card from "@mui/material/Card/Card";
import CardHeader from "@mui/material/CardHeader/CardHeader";
import Avatar from "@mui/material/Avatar/Avatar";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import Badge from "@mui/material/Badge/Badge";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

interface HourPickerState {
  date: Value;
  hours: HourState;
  isOpen: boolean;
  isCancelModalOpen: boolean;
  highlightedDates: Date[];
  bandName: string;
}

class HourPicker extends React.Component<any, HourPickerState> {
  static contextType = MainAppContext;
  state = {
    date: new Date(),
    hours: initialHoursState,
    isOpen: false,
    isCancelModalOpen: false,
    highlightedDates: [],
    bandName: "",
  };

  constructor(public context: React.ContextType<typeof MainAppContext>) {
    super(context);
    this.onDateChange = this.onDateChange.bind(this);
    this.fetchHours = this.fetchHours.bind(this);
    this.fetchBookings = this.fetchBookings.bind(this);
    this.handleHourClick = this.handleHourClick.bind(this);
    this.handleBookRehearsal = this.handleBookRehearsal.bind(this);
    this.handleBandNameChange = this.handleBandNameChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  public componentDidMount() {
    this.fetchHours();
    this.fetchBookings();
  }

  public componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.date !== this.state.date) {
      this.fetchHours();
      this.fetchBookings();
    }
  }

  public render() {
    const { user, isLoggedIn, openDrawer, isSeenNotification } = this.context;
    const hours = Object.keys(this.state.hours);
    const currentDate = dayjs(new Date()).subtract(1, "day");
    const dateBeforeCurrent = dayjs(dayjs(this.state.date)).isBefore(
      currentDate
    );
    return (
      <div className="timetable">
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={"ru"}
          localeText={
            ruRU.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <Card>
            <CardHeader
              avatar={
                <img
                  src="/assets/logo_seal.png"
                  alt="logo"
                  width={40}
                  style={{ height: "40px!important" }}
                />
              }
              action={
                <Button onClick={openDrawer}>
                  <Badge color="secondary" variant="dot" invisible={isSeenNotification}>
                    <Avatar
                      imgProps={{
                        sx: {
                          backgroundImage: "url(/assets/vinyl.svg)",
                          backgroundSize: "contain",
                        },
                      }}
                    >
                      <img
                        src={
                          user?.photo_url ? user.photo_url : "/logo_main512.png"
                        }
                        alt="logo"
                        width={40}
                        style={{ height: "40px!important" }}
                      />
                    </Avatar>
                  </Badge>
                </Button>
              }
              title="Тюленева 25"
              subheader="Расписание студии"
            />
            <DateCalendar
              onChange={this.onDateChange}
              value={dayjs(this.state.date)}
              slots={{ day: BookedDay }}
              onMonthChange={this.onDateChange}
              slotProps={{
                day: { highlightedDays: this.state.highlightedDates } as any,
              }}
            />
          </Card>
        </LocalizationProvider>
        <div className="hourPicker">
          {/** Buttons for hours selection */}
          {hours.map((hour: string, index: number) => (
            <HourButton
              key={index}
              hour={hour}
              status={this.state.hours[hour].status}
              owner={this.state.hours[hour].owner}
              isOwn={this.state.hours[hour].userId === user?.id}
              bandname={this.state.hours[hour].bandName}
              avatar={this.state.hours[hour].avatar}
              onClick={this.handleHourClick}
              isCancelling={this.state.hours[hour].isCancelling}
            />
          ))}
        </div>
        {this.selectedHours.length > 0 && !dateBeforeCurrent && (
          <Button
            className="bookBtn"
            type="button"
            onClick={this.openModal}
            variant="outlined"
            color="success"
            startIcon={<CheckIcon />}
          >
            Забронировать
          </Button>
        )}
        {this.cancellingHours.length > 0 &&
          !dateBeforeCurrent &&
          isLoggedIn && (
            <Button
              className="bookBtn"
              type="button"
              onClick={this.openCancelModal}
              variant="outlined"
              startIcon={<DeleteIcon />}
              color="error"
            >
              Отменить
            </Button>
          )}
        <HourModal
          isOpen={this.state.isOpen}
          date={this.state.date}
          onClose={this.closeModal}
          hours={this.selectedHours}
          firstName={user?.first_name}
          lastName={user?.last_name}
          onConfirm={this.handleBookRehearsal}
          onBandNameChange={this.handleBandNameChange}
          bandName={this.state.bandName}
        />
        <CancelModal
          isOpen={this.state.isCancelModalOpen}
          onClose={this.closeCancelModal}
          hours={Object.keys(this.state.hours).filter(
            (hour) => this.state.hours[hour].isCancelling
          )}
          onCancel={this.handleCancel}
        />
      </div>
    );
  }

  public onDateChange(date: Dayjs) {
    this.setState({ date: dayjs(date).toDate() });
  }

  public async fetchHours() {
    if (this.state.date) {
      const response = await fetch(
        API_URL +
          HOURS_URL +
          "?" +
          new URLSearchParams({
            date: dayjs(this.state.date).format("DD/MM/YYYY"),
          })
      );
      const respJson = await response.json();
      if (respJson.result) {
        const bookedHours = respJson.result.hours;
        this.setState({ hours: bookedHours });
      } else {
        this.setState({ hours: initialHoursState });
      }
    }
  }

  public async fetchBookings() {
    try {
      const { date } = this.state;
      if (date) {
        const response = await fetch(
          API_URL +
            BOOKINGS_URL +
            "?" +
            new URLSearchParams({
              date: dayjs(this.state.date).format("DD/MM/YYYY"),
            })
        );
        const dates = await response.json();

        if (dates.result) {
          const datesToHighlight = dates.result.map((d: Date) => {
            const a = new Date(d);
            return new Date(a.setDate(a.getDate())).getDate();
          });
          this.setState({ highlightedDates: datesToHighlight });
        } else {
          this.setState({ highlightedDates: [] });
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  public openModal = () => {
    this.setState({ isOpen: true });
  };

  public openCancelModal = () => {
    this.setState({ isCancelModalOpen: true });
  };

  public closeModal = () => {
    this.setState({ isOpen: false });
  };

  public closeCancelModal = () => {
    this.setState({ isCancelModalOpen: false });
  };

  public handleBandNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    this.setState({ bandName: value });
  }

  public handleHourClick(hour: string, isOwn?: boolean) {
    let updatedHourState = {
      ...this.state.hours,
      [hour]: {
        ...this.state.hours[hour],
      },
    };
    if (isOwn) {
      const isCancelling = !this.state.hours[hour].isCancelling;
      updatedHourState = {
        ...this.state.hours,
        [hour]: {
          ...this.state.hours[hour],
          isCancelling,
        },
      };
    } else {
      const newStatus =
        this.state.hours[hour].status === HourStatuses.SELECTED
          ? HourStatuses.NONE
          : HourStatuses.SELECTED;
      updatedHourState = {
        ...this.state.hours,
        [hour]: {
          ...this.state.hours[hour],
          status: newStatus,
        },
      };
    }

    this.setState({ hours: updatedHourState });
  }

  public get selectedHours() {
    return Object.keys(this.state.hours).filter(
      (hour) => this.state.hours[hour].status === HourStatuses.SELECTED
    );
  }

  public get cancellingHours() {
    return Object.keys(this.state.hours).filter(
      (hour) => this.state.hours[hour].isCancelling
    );
  }

  public async handleBookRehearsal() {
    const { showToast } = this.context;
    const selectedHoursArr = this.selectedHours.map((hour) => ({
      [hour]: {
        ...this.state.hours[hour],
        status: HourStatuses.BOOKED,
        userId: this.context.user?.id,
        bandName: this.state.bandName,
        owner: this.context.user?.first_name,
        avatar: this.context.user?.photo_url,
      },
    }));
    const selectedHoursObj = Object.assign({}, ...selectedHoursArr);
    const updatedHourState = {
      ...this.state.hours,
      ...selectedHoursObj,
    };
    await fetch(API_URL + BOOK_URL, {
      method: "POST",
      body: JSON.stringify({
        date: dayjs(this.state.date).format("DD/MM/YYYY"),
        hours: updatedHourState,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    showToast("Время было забронировано. Дождитесь подтверждения.", "info");
    this.fetchHours();
    this.fetchBookings();
    this.closeModal();
  }

  public async handleCancel() {
    const { showToast } = this.context;
    const cancellingHoursArr = this.cancellingHours.map((hour) => ({
      [hour]: {
        ...this.state.hours[hour],
        status: HourStatuses.NONE,
        owner: null,
        userId: null,
        bandName: "",
        avatar: null,
        isCancelling: false,
      },
    }));
    const cancellingHoursObj = Object.assign({}, ...cancellingHoursArr);
    const updatedHourState = {
      ...this.state.hours,
      ...cancellingHoursObj,
    };
    await fetch(API_URL + CANCEL_URL, {
      method: "POST",
      body: JSON.stringify({
        date: dayjs(this.state.date).format("DD/MM/YYYY"),
        hours: updatedHourState,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    showToast("Бронирование было отменено.", "info");
    this.fetchHours();
    this.fetchBookings();
    this.closeCancelModal();
  }
}

export default HourPicker;
